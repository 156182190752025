import React from "react"
import Layout from "../components/layout"
import Image from "../components/Image"
import { Row, Col } from 'react-bootstrap'
import Fade from 'react-reveal/Fade'
import Content from "../components/Content"


const Post = ({ pageContext }) => (
  <Layout>
    <Fade>
      <Row>
        <Col md={pageContext?.images?.length > 0 ? 8 : 12} className="pe-md-5">
          <span>{pageContext.date}</span>
          <h1 className="mb-5 page-header">{pageContext.title}</h1>
          <Content>{pageContext.content.data.content}</Content>
        </Col>
        <Col md={pageContext?.images?.length > 0 ? 4 : 0} className="ps-md-3">
          {pageContext?.images?.map(image => (
            <Image image={image} />
          ))}
        </Col>
      </Row>
    </Fade>
  </Layout>
)

export default Post
